import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import {
  Cta, CarouselCards, Spinner, CardOutline, Seo,
} from '../../components'
import ctaImage from '../../assets/images/about-us/pacificcross_aboutgroup_cta.jpeg'

import {
  BlockHead,
  CtaAboutUs,
  OurVision,
  OurMilestones,
  OurValues,
  OurLocations,
} from './components'
import './about-us-styles.scss'

const AboutUsView = ({ loading, data }) => {
  const { t, i18n } = useTranslation()
  const breakpoints = useBreakpoint()

  const ourMilestones = _.map(_.get(data, 'content.our_milestones'), (item) => ({
    title: item.year,
    content: item.description,
  }))

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <div className="aboutUs__container container-fluid r-0 p-0">
      <Seo title={`${t('seo.title')}: About us`} />
      <BlockHead
        sectionOne={_.head(_.get(data, 'content.section_1'))}
        sectionTwo={_.head(_.get(data, 'content.section_2'))}
      />
      <CtaAboutUs data={_.head(_.get(data, 'content.our_mission'))} t={t} />

      <div className="container-xxl p-0">
        <OurVision t={t} data={_.head(_.get(data, 'content.our_vision'))} />
        <OurLocations
          t={t}
          data={_.head(_.get(data, 'content.our_locations'))}
        />
        {breakpoints.xs ? (
          <div className="aboutUsOurValues__container">
            <CarouselCards
              data={_.get(data, 'content.our_values')}
              inner={CardOutline}
              tag="our Values"
              title="What we believe in"
            />
          </div>
        ) : (
          <OurValues t={t} data={_.get(data, 'content.our_values')} />
        )}

        <OurMilestones t={t} i18n={i18n} data={ourMilestones} />
        <Cta
          withAppend={true}
          img={ctaImage}
          info={{
            buttonText: t('footer.contact.button'),
          }}
        />
      </div>
    </div>
  )
}

export default AboutUsView
