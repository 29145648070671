import _ from 'lodash'
import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import AboutUsView from './about-us-view'

const AboutUsController = (props) => {
  const { uri } = props
  const slug = _.last(_.split(uri, '/'))
  const [page, setPage] = useState([])
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const getAboutUsPage = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'page',
      params: {
        lang: i18n.language,
        slug,
      },
    }).then((resp) => {
      setPage(resp.data)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    getAboutUsPage()
  }, [i18n.language])

  const viewProps = {
    loading,
    data: page,
    t,
  }
  return <AboutUsView {...viewProps} />
}

export default AboutUsController
